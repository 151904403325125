$carolinaBlue: #5fa4d0;

@font-face {
    font-family: "Recia";
    src: local("Recia"),
        url("../../assets/fonts/Recia/Recia-Semibold.woff2") format("woff2"),
        url("../../assets/fonts/Recia/Recia-Semibold.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
        url("../../assets/fonts/Satoshi/Satoshi-Regular.woff2") format("woff2"),
        url("../../assets/fonts/Satoshi/Satoshi-Regular.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
        url("../../assets/fonts/Satoshi/Satoshi-Medium.woff2") format("woff2"),
        url("../../assets/fonts/Satoshi/Satoshi-Medium.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
        url("../../assets/fonts/Satoshi/Satoshi-Bold.woff2") format("woff2"),
        url("../../assets/fonts/Satoshi/Satoshi-Bold.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

html {
    background-color: white;
}

.slick-slider {
    width: 100%;
}

.slick-dots {
    position: absolute;
    top: 289px;
    li {
        width: 8px;
    }
    li.slick-active button:before {
        color: $carolinaBlue;
    }
}

.slick-slide > div {
    margin: 0 10px;
    display: flex;
    justify-content: center;
}

.slick-list {
    margin: 0 -10px;
}

.slick-dots li button:before {
    font-size: 10px;
    line-height: 10px;
}

/* Chrome, Safari , WebKit */
::-webkit-scrollbar {
    display: none !important;
}

/* Firefox */
html {
    scrollbar-width: none !important;
}

/* IE , Edge */
body {
    -ms-overflow-style: none !important;
}

* {
    font-family: "Satoshi", sans-serif;
    box-sizing: border-box;
}
ion-col {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0;
    padding-bottom: 0;
}
ion-row {
    margin-left: -8px;
    margin-right: -8px;
    &.no-gutters {
        margin-left: 0;
        margin-right: 0;
        ion-col {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
ion-grid {
    padding: 0;
}

ion-modal {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

//radio button quizz
